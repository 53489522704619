














































































































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { Demand } from "@/store/models/DemandAndSupply";
import {
  CropVarietyStore,
  CropStore,
  DemandSupplyStore,
  MetaStore,
  JWTStore
} from "@/store/modules";
import { MetaInfo } from "vue-meta";
import { convertADtoBS, nepaliDate } from "@/store/modules/dateConverter";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";
import { roleChecker } from "@/utils/jwt";

@Component({
  components: {},
  metaInfo(): MetaInfo {
    return {
      title: "Demand List",
      meta: [
        { name: "keyword", content: "Sqcc demand list" },
        {
          name: "description",
          content: "List of Demands of seeds",
          vmid: "demandList"
        }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchCompany: string | null = null;
  searchCode: string | null = null;
  fiscalYearId: number | null = null;
  cropId: number | null = null;
  cropVarietyId: number | null = null;
  seedTypeId: number | null = null;
  districtId: number | null = null;
  status: string | null = null;
  pageNum: number = 1;
  clusterId: number = 0;
  language: string = "";
  loading: boolean = false;
  role: string = "";
  dialogVisible: boolean = false;

  updateseedquantitydialogVisible: boolean = false;

  DemandAndSupply: Demand = {} as Demand;

  get dataList() {
    return DemandSupplyStore.PaginatedDemand;
  }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne" && value) {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  indexMethod(index: number) {
    let i = (this.dataList.page - 1) * this.dataList.page_size + index + 1;
    return this.nepaliUnicodeNumber(i);
  }

  nepaliDate(date: string) {
    return nepaliDate(date, this.$i18n.locale);
  }

  roleChecker(roles: string[]) {
    return roleChecker(roles);
  }

  get districts() {
    return MetaStore.District;
  }

  get fiscalyears() {
    return MetaStore.FiscalYear;
  }

  get crops() {
    let crops = DemandSupplyStore.FyWiseSubsidyCrops.map(x => {
      return { fiscal_year: x.fiscal_year, name: x.crop_name, id: x.crop_type };
    });
    if (this.fiscalYearId) {
      crops = crops.filter(x => x.fiscal_year == this.fiscalYearId);
    }
    crops = [...new Map(crops.map(item => [item["id"], item])).values()];

    return crops;
  }

  get cropVarieties() {
    let cropVarieties = DemandSupplyStore.FyWiseSubsidy.map(x => {
      return {
        fiscal_year: x.fiscal_year,
        crop_type: x.crop_type,
        name: x.variety_name,
        id: x.crop_variety
      };
    });
    if (this.fiscalYearId || this.cropId) {
      if (this.cropId) {
        cropVarieties = cropVarieties.filter(x => x.crop_type == this.cropId);
      }
      if (this.fiscalYearId) {
        cropVarieties = cropVarieties.filter(x => x.fiscal_year == this.fiscalYearId);
      }
    }
    cropVarieties = [...new Map(cropVarieties.map(item => [item["id"], item])).values()];
    return cropVarieties;
  }

  get seedTypes() {
    return DemandSupplyStore.SeedTypeList;
  }

  changeCrop() {
    this.cropVarietyId = null;
  }

  thousandSeperator(amount: number) {
    if (amount == null) {
      return null;
    }
    amount = Number(amount);
    if (amount !== undefined || amount !== 0 || amount !== "0" || amount !== null) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return amount;
    }
  }

  search() {
    let query: any = { page: 1 };
    this.pageNum = 1;
    // if (this.fiscalYearId != null) {
    //   query["fiscalYearId"] = String(this.fiscalYearId);
    // }
    // if (this.cropId != null) {
    //   query["cropId"] = String(this.cropId);
    // }
    // if (this.cropVarietyId != null) {
    //   query["cropVarietyId"] = String(this.cropVarietyId);
    // }
    // if (this.seedTypeId != null) {
    //   query["seedTypeId"] = String(this.seedTypeId);
    // }
    // if (this.status != null) {
    //   query["status"] = String(this.status);
    // }
    // if (this.searchCompany != null) {
    //   query["searchCompany"] = String(this.searchCompany);
    // }
    // if (this.searchCode != null) {
    //   query["searchCode"] = String(this.searchCode);
    // }
    query = this.routerReplacer();
    DemandSupplyStore.getAdminDemands(query);
    DemandSupplyStore.getAdminDemandsTotalQuantity(query);
  }

  clearSearch() {
    let query: any = { page: 1 };
    this.pageNum = 1;
    this.fiscalYearId = null;
    this.cropId = null;
    this.cropVarietyId = null;
    this.seedTypeId = null;
    this.status = null;
    this.searchCode = null;
    this.searchCompany = null;
    query = this.routerReplacer();
    DemandSupplyStore.getAdminDemands(query);
    DemandSupplyStore.getAdminDemandsTotalQuantity(query);
  }

  routerReplacer() {
    let query: any = {};
    if (this.pageNum) {
      query["page"] = String(this.pageNum);
    }
    if (this.fiscalYearId != null) {
      query["fiscalYearId"] = String(this.fiscalYearId);
    }
    if (this.cropId != null) {
      query["cropId"] = String(this.cropId);
    }
    if (this.cropVarietyId != null) {
      query["cropVarietyId"] = String(this.cropVarietyId);
    }
    // if role is ISMAdmin (Improved Seed Manager Admin), then pass seedTypeId = 4 (id of seedtype 'Improved Seed' )
    if (this.role == "ISMAdmin" || this.role == "ISSMAdmin") {
      query["seedTypeId"] = String(4);
    } else if (this.seedTypeId != null) {
      query["seedTypeId"] = String(this.seedTypeId);
    }
    if (this.status != null) {
      query["status"] = String(this.status);
    }
    if (this.searchCompany != null) {
      query["searchCompany"] = String(this.searchCompany);
    }
    if (this.searchCode != null) {
      query["searchCode"] = String(this.searchCode);
    }
    if (this.$i18n.locale != null) {
      query["lang"] = String(this.$i18n.locale);
    }
    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: query
        })
        .catch(error => {});
    }
    return query;
  }

  async handlePageChange(val: number) {
    this.pageNum = val;
    this.loading = true;
    let query = this.routerReplacer();
    await DemandSupplyStore.getAdminDemands(query);
    this.loading = false;
  }

  toogleExpand(row: any) {
    let $table = this.$refs.dataTable as any;
    $table.toggleRowExpansion(row);
  }

  viewBill() {
    this.dialogVisible = true;
  }

  private async created() {
    MetaStore.getAllDistrict();
    MetaStore.getAllFiscalYear();
    DemandSupplyStore.getFyWiseSubsidyCrops({ lang: this.$i18n.locale });
    DemandSupplyStore.getFyWiseSubsidy({ lang: this.$i18n.locale });
    DemandSupplyStore.getAllSeedType();

    this.role = JWTStore.role;
    this.language = this.$i18n.locale;

    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }
    if (this.$route.query.cropId) {
      this.cropId = Number(this.$route.query.cropId);
    }
    if (this.$route.query.cropVarietyId) {
      this.cropVarietyId = Number(this.$route.query.cropVarietyId);
    }
    if (this.$route.query.seedTypeId) {
      this.seedTypeId = Number(this.$route.query.seedTypeId);
    }
    if (this.$route.query.status) {
      this.status = String(this.$route.query.status);
    }
    if (this.$route.query.searchCompany) {
      this.searchCompany = String(this.$route.query.searchCompany);
    }
    if (this.$route.query.searchCode) {
      this.searchCode = String(this.$route.query.searchCode);
    }
    let query = this.routerReplacer();
    this.loading = true;
    await DemandSupplyStore.getAdminDemands(query);
    this.loading = false;
    DemandSupplyStore.getAdminDemandsTotalQuantity(query);
  }

  async viewForm(data: Demand) {
    this.DemandAndSupply = data;
    this.dialogVisible = true;
  }

  async UpdateDemandQuantity(id: number, seed_quantity: number) {
    console.log(id, seed_quantity);
    if (this.DemandAndSupply.id != undefined && this.DemandAndSupply.id != 0) {
      let obj = { id: id, seedquantity: seed_quantity };
      console.log(obj);
      const res = await DemandSupplyStore.updateAdminDemand(obj);
      if (res) {
        this.$notify.success("Seed quantity updated successfully");
      }
      this.dialogVisible = false;
    }
  }

  get totalDemandQuantity() {
    return DemandSupplyStore.TotalDemandQuantity;
  }

  getSummaries(param: any) {
    const { columns, data } = param;
    const sums: (number | string)[] = [];
    columns.forEach((column: any, index: number) => {
      if (index === 1) {
        sums[index] = this.$t("grandTotal").toString();
        return;
      } else {
        sums[index] = "";
      }

      if (index === 8 && this.totalDemandQuantity.total_quantity) {
        const value = this.thousandSeperator(this.totalDemandQuantity.total_quantity);
        sums[index] = nepaliUnicodeNumber(value ? value : "");
        return;
      }
    });
    return sums;
  }
}
