





















































































































































import { Vue, Component } from "vue-property-decorator";
import { UserStore, DSADStore, JWTStore } from "@/store/modules";
import { User } from "@/store/models/user";
import { DSADGroup } from "@/store/models/dsad";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";

@Component({
  metaInfo: {
    title: "Demand Supply Schedule",
    meta: [
      {
        name: "description",
        content: "List of demand supply group",
        vmid: "demandList"
      }
    ]
  }
})
export default class userList extends Vue {
  dialogVisible: boolean = false;
  innerVisible: boolean = false;
  loading: boolean = false;
  selectedIndex = -1;
  data: User = {} as User;
  activeName: string = "general";
  searchString: string = "";
  pageNum: number = 1;
  role: string = "";

  maxListLength = 5;
  currentListLength = 5;
  moreButtonTitle = "More";

  get month() {
    let month = [
      "",
      "Baishakh",
      "Jeth",
      "Asar",
      "Shrawan",
      "Bhadra",
      "Asoj",
      "Kartik",
      "Manshir",
      "Paush",
      "Magh",
      "Falgun",
      "Chaitra"
    ];
    if (this.$i18n.locale == "ne") {
      month = [
        "",
        "वैशाख",
        "जेठ",
        "असार",
        "साउन",
        "भदौ",
        "असोज",
        "कार्तिक",
        "मंसिर",
        "पुष",
        "माघ",
        "फागुन",
        "चैत"
      ];
    }
    return month;
  }

  nepaliUnicodeNumber(value: number) {
    if (this.$i18n.locale == "ne") {
      return nepaliUnicodeNumber(String(value));
    }
    return value;
  }

  indexMethod(index: number) {
    let i = (this.dataList.page - 1) * this.dataList.page_size + index + 1;
    return i;
  }

  monthName(month: number) {
    return this.month[month];
  }

  get dataList() {
    return DSADStore.DSADGroupList;
  }

  get adminList() {
    return UserStore.AdminUsers;
  }

  get generalUserList() {
    return UserStore.GeneralUsers;
  }

  addUser() {
    this.$validator.reset();
    // this.dialogVisible = true;
    this.selectedIndex = -1;
    this.data = {} as User;
  }

  editForm(id: number) {
    this.$router
      .push({
        name: "AddDemandSupplyGroup",
        query: { dsadId: String(id), page: this.$route.query.page }
      })
      .catch(error => {});
  }

  async submit() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      // console.log("submit");
      this.dialogVisible = false;
    } else {
      this.$message.error("Form  Invalid");
    }
  }

  private async created() {
    this.role = JWTStore.role;
    let array = [
      {
        id: 63,
        varietyname: "Barshe - 2014",
        selected: true,
        groupid: 33,
        varietyid: 175
      },
      {
        id: 64,
        varietyname: "Barshe - 3004",
        selected: true,
        groupid: 33,
        varietyid: 196
      },
      {
        id: 65,
        varietyname: "Ceiherang Sub -1",
        selected: true,
        groupid: 33,
        varietyid: 163
      },
      {
        id: 66,
        varietyname: "Ceiherang Sub -1",
        selected: true,
        groupid: 33,
        varietyid: 163
      }
    ];
    console.log(
      array
        .map(item => item.varietyname)
        .slice(0, 3)
        .join(", ")
    );
    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }

    this.loading = true;
    await DSADStore.getAllDSADGroup({
      search: this.searchString,
      page: this.pageNum,
      lang: this.$i18n.locale,
      role: this.role
    });
    this.loading = false;
  }

  async handlePrevNextClick(val: any) {
    this.loading = true;
    await DSADStore.getAllDSADGroup({
      search: this.searchString,
      page: val,
      lang: this.$i18n.locale,
      role: this.role
    });
    this.loading = false;
    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: { page: String(val) }
        })
        .catch(error => {});
    }
  }

  searchText() {
    DSADStore.getAllDSADGroup({
      search: this.searchString,
      page: 1,
      role: this.role
    });
    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: { page: String(1) }
        })
        .catch(error => {});
    }
  }

  onDeleteClick(index: number, id: number) {
    let dsadGroups = this.dataList.results.filter(x => x.id == id);
    let dsadGroup: DSADGroup = {} as DSADGroup;
    if (dsadGroups.length > 0) {
      dsadGroup = dsadGroups[0];

      this.$confirm(
        "This will permanently delete the DSAD group " +
          dsadGroup.group +
          " and all related data. This cannot be undone. Continue?",
        "Warning",
        {
          confirmButtonText: "Delete",
          confirmButtonClass: "el-button--danger",
          cancelButtonText: "Cancel",
          showClose: false,
          type: "warning"
        }
      )
        .then(async () => {
          await DSADStore.deleteDSADGroupById(id);
          await DSADStore.getAllDSADGroup({
            search: this.searchString,
            page: this.pageNum,
            role: this.role
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete cancelled"
          });
        });
    }
  }

  toggleMore(item: any) {
    if (item.currentListLength == this.maxListLength) {
      item.currentListLength = -1;
    } else {
      item.currentListLength = this.maxListLength;
    }
  }
}
