






















































































































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";

import { MetaStore, UserStore } from "@/store/modules";
import { MetaInfo } from "vue-meta";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";
import { CompanyUser } from "@/store/models/user";

import DetailTag from "@/components/UIComponents/DetailTag.vue";
import UserDetailDocument from "@/views/Admin/Components/UserDetailDocument.vue";

@Component({
  components: { DetailTag, UserDetailDocument },
  metaInfo(): MetaInfo {
    return {
      title: "Document List",
      meta: [
        { name: "keyword", content: "Sqcc document list" },
        {
          name: "description",
          content: "List of renewed documents of organizations",
          vmid: "demandList"
        }
      ]
    };
  }
})
export default class DocumentList extends Vue {
  pageNum: number = 1;
  language: string = "";
  loading: boolean = false;

  selectedProvince: number | null = null;
  selectedDistrict: number | null = null;
  selectedPalika: number | null = null;
  reapproveStatus: string | null = null;
  selectedRole: string | null = null;
  searchString: string = "";

  detailVisible: boolean = false;

  userDetail: CompanyUser = {} as CompanyUser;

  get dataList() {
    return UserStore.UserDocuments;
  }

  get provinces() {
    return MetaStore.Province;
  }

  get districts() {
    let district = MetaStore.District;
    if (this.selectedProvince) {
      district = district.filter(x => x.provinceid == this.selectedProvince);
    } else {
      district = [];
    }
    return district;
  }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne") {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  indexMethod(index: number) {
    let i = (this.dataList.page - 1) * this.dataList.page_size + index + 1;
    return this.nepaliUnicodeNumber(i);
  }

  thousandSeprator(amount: number) {
    if (amount == null) {
      return null;
    }
    amount = Number(amount);
    if (amount !== undefined || amount !== 0 || amount !== "0" || amount !== null) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return amount;
    }
  }

  queryBuilder(page?: number) {
    let query: any = {};
    if (page != null) {
      query["page"] = String(page);
    }
    if (this.searchString != null && this.searchString != "") {
      query["searchString"] = this.searchString;
    }
    if (this.selectedProvince != null) {
      query["selectedProvince"] = this.selectedProvince;
    }
    if (this.selectedDistrict != null) {
      query["selectedDistrict"] = this.selectedDistrict;
    }
    if (this.selectedPalika != null) {
      query["selectedPalika"] = this.selectedPalika;
    }
    if (this.reapproveStatus != null) {
      query["reapproveStatus"] = this.reapproveStatus;
    }
    if (this.selectedRole != null) {
      query["selectedRole"] = String(this.selectedRole);
    }
    // if (this.$route.name) {
    //   this.$router
    //     .replace({
    //       name: this.$route.name,
    //       query: query
    //     })
    //     .catch(error => {});
    // }
    return query;
  }

  async loadDocumentList(page = 1) {
    let query = this.queryBuilder(page);
    this.loading = true;
    await UserStore.getUploadedUserDocuments(query);
    this.loading = false;
  }

  clearFilter() {
    this.selectedProvince = null;
    this.selectedPalika = null;
    this.selectedDistrict = null;
    this.selectedRole = null;
    this.reapproveStatus = null;
    this.searchString = "";
    this.loadDocumentList();
  }

  async handlePageChange(val: number) {
    this.pageNum = val;
    this.loadDocumentList(this.pageNum);
  }

  changeProvince() {
    this.selectedDistrict = null;
    this.selectedPalika = null;
  }

  clearProvince() {
    this.selectedProvince = null;
    this.selectedDistrict = null;
    this.selectedPalika = null;
  }

  viewDetail(user: CompanyUser) {
    this.detailVisible = true;
    this.userDetail = user;
  }

  async approveDocument() {
    let error = false;
    await UserStore.approveUserDocument(this.userDetail.id).catch(err => {
      error = true;
    });
    if (!error) {
      this.loadDocumentList();
      this.$notify({
        title: this.$t("documentsApproved").toString(),
        message: `${this.$t("documentsApprovedMessage")}`,
        type: "success"
      });
      this.detailVisible = false;
    }
  }

  async rejectDocument() {
    let error = false;
    await UserStore.rejectUserDocument(this.userDetail.id).catch(err => {
      error = true;
    });
    if (!error) {
      this.loadDocumentList();
      this.$notify({
        title: this.$t("documentsRejected").toString(),
        message: `${this.$t("documentsRejectedMessage")}`,
        type: "success"
      });
      this.detailVisible = false;
    }
  }

  async created() {
    MetaStore.getAllProvince();
    MetaStore.getAllDistrict();

    this.language = this.$i18n.locale;
    this.loadDocumentList();
  }
}
